import { post } from 'Api';
import store from 'Redux/store';
import { getPersistedRecentlyViewedSlugs } from 'Redux/ducks/recently-viewed';
import { USER_RECOMMENDATIONS_FETCH } from 'Redux/reducers/user-recommendations';

export async function getUserRecommendations() {
  const recentlyViewedSlugs = getPersistedRecentlyViewedSlugs();

  return post(store.dispatch, '/user/recommendations', recentlyViewedSlugs);
}

export async function fetchAndDispatchUserRecommendations() {
  return getUserRecommendations()
    .then(({ data }) => {
    // Only show and trigger split test if there are enough hits
      if (data.hits?.length >= 12 && data.totalHits >= 36 && data.sources >= 10) {
        store.dispatch({ type: USER_RECOMMENDATIONS_FETCH, payload: data.hits });
      }
    })
    .catch(() => {});
}

export async function logViewed(slugs) {
  return post(store.dispatch, '/user/recommendations/viewed', slugs);
}
