import isEmpty from 'lodash/isEmpty';

import { fetchBySlugs } from 'Redux/ducks/algolia-calls';

export const ADD_TO_RECENTLY_VIEWED = 'frontend/recently-viewed/ADD_TO_RECENTLY_VIEWED';
export const FETCH_RECENT_VIEWED = 'frontend/recently-viewed/FETCH_RECENT_VIEWED';

/**
 * Remove duplicates from an array
 * @param  {Array.string} arr array to remove duplicates from
 * @return {Array.String}     Array returned with duplicates
 */
const removeDuplicates = arr =>
  arr.filter((slug, index) => arr.indexOf(slug) >= index);

/**
 * Get recently viewed product slugs from local storage
 * @return {Array.string}  recently viewed product slugs
 */
export function getPersistedRecentlyViewedSlugs() {
  let recentlyViewed = [];
  try {
    recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
  } catch (ex) { /* */ }

  return recentlyViewed;
}

/**
 * Adds the slug of a passed in product to recently viewed in local storage
 * @param {Object} product product to add to recently viewed
 * @return {Object}                recentlyViewedSlugs slugs to dispatch
 */

export function addToRecentlyViewed(product) {
  let recentlyViewedSlugs = getPersistedRecentlyViewedSlugs();

  recentlyViewedSlugs.unshift(product.slug);
  recentlyViewedSlugs = removeDuplicates(recentlyViewedSlugs).slice(0, 36);

  localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewedSlugs));

  return { type: ADD_TO_RECENTLY_VIEWED, payload: recentlyViewedSlugs };
}

/**
 * Removes a product / products from the recently viewed when processing an order
 * @param  {Array.String} purchasedSlugs slugs of recently purchased products
 * @return {Object}              unpurchasedRecentlyViewed slugs to dispatch
 */
export function removePurchasedFromRecentlyViewed(purchasedSlugs) {
  const recentlyViewedSlugs = getPersistedRecentlyViewedSlugs();
  const unpurchasedRecentlyViewed = recentlyViewedSlugs.filter(s => !purchasedSlugs.includes(s));

  localStorage.setItem('recentlyViewed', JSON.stringify(unpurchasedRecentlyViewed));

  return { type: ADD_TO_RECENTLY_VIEWED, payload: unpurchasedRecentlyViewed };
}

/**
 * Fetches recently viewed items from algolia
 * @param hitsToDisplay the amount of hits to fetch in algolia call
 * @param slugsToExclude slugs we wish to exclude from recentlyViewed
 * @return {Function} recentlyViewedHits to dispatch
 */
export function fetchRecentlyViewed(hitsToDisplay, slugsToExclude) {
  return async (dispatch) => {
    let recentlyViewedSlugs = getPersistedRecentlyViewedSlugs();

    if (!isEmpty(slugsToExclude)) {
      recentlyViewedSlugs = recentlyViewedSlugs.filter(slug => !slugsToExclude.includes(slug));
    }

    const recentlyViewedHits =
      await fetchBySlugs([recentlyViewedSlugs.slice(0, hitsToDisplay)]);
    return dispatch({ type: FETCH_RECENT_VIEWED, payload: recentlyViewedHits[0] });
  };
}
